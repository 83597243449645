import { template as template_1dfa32b378ef4b6e9eefdf6017d1b2cb } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_1dfa32b378ef4b6e9eefdf6017d1b2cb(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
