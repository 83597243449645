import { template as template_247714201b9342208f080451a54e18b1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_247714201b9342208f080451a54e18b1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
